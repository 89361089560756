import { gsap, ScrollTrigger } from 'gsap/all';

gsap.registerPlugin(ScrollTrigger);

function scrollAnimation() {
    const triggers = document.querySelectorAll('.js-scrollTrigger');

    if (triggers.length === 0) return false;

    triggers.forEach((trigger) => {
        ScrollTrigger.create({
            trigger,
            start: '0% 50%',
            end: '0% 50%',
            markers: false,
            onEnter: () => {
                trigger.classList.add('is-active');
            },
            onEnterBack: () => {
                trigger.classList.remove('is-active');
            },
        });
    });

    return true;
}

function scrollAnimationDino() {
    const triggers = document.querySelectorAll('.js-scrollTriggerDino');

    if (triggers.length === 0) return false;

    triggers.forEach((trigger) => {
        const x = trigger.classList.contains('_left') ? '-100%' : '100%';
        gsap.fromTo(trigger, {
            autoAlpha: 0,
            x,
        }, {
            autoAlpha: 1,
            x: 0,
            scrollTrigger: {
                trigger,
                start: '0% 98%',
                end: '100% 75%',
                scrub: true,
                markers: false,
            },
        });
    });

    return true;
}

export { scrollAnimation, scrollAnimationDino };
