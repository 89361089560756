import { setContentsSlider } from './components/slider';
import { initSlider, setTopSpotSlider, setTopEventSlider } from './components/sliderSlick';
import { setTopMap, setCourseMap, setCourseFooterMap, setDinoMap } from './components/map';
import setMenuMain from './components/menuMain';
import selectLang from './components/selectLang';
import menuSp from './components/menuSp';
import mouseStalker from './components/mouseStalker';
import { scrollAnimation, scrollAnimationDino } from './components/scrollAnimation';
import toTop from './components/toTop';
import pageCourse from './components/pageCourse';
// import topMainHero from './components/topMainHero';

window.addEventListener('load', () => {
    /* スライダー */
    initSlider();
    setTopSpotSlider();
    setTopEventSlider();
    setContentsSlider();

    /**
     * マップ関連
     */
    setTopMap();
    setCourseMap();
    setCourseFooterMap();
    setDinoMap();

    setMenuMain();
    selectLang();
    menuSp();
    mouseStalker();
    scrollAnimation();
    scrollAnimationDino();
    toTop();
    pageCourse();
});

// window.addEventListener('DOMContentLoaded', () => {
//     /* TOP ヒーローイメージ */
//     topMainHero();
// });
