export default function mouseStalker() {
    const stalker = document.querySelector('#js-mouseStalker');

    if (stalker === null || window.ontouchstart !== undefined) return false;

    const stalkerWidth = stalker.clientWidth;
    const stalkerHeight = stalker.clientHeight;

    document.addEventListener('mousemove', (e) => {
        stalker.style.translate = `${e.clientX - (stalkerWidth / 2)}px ${e.clientY - (stalkerHeight / 2)}px`;
    }, { passive: true });

    document.querySelectorAll('.js-mouse-stalker').forEach((item) => {
        item.addEventListener('mouseenter', () => {
            const { color } = item.dataset;
            // if (item.classList.contains('no-mouse-stalker')) {
            //     return;
            // }
            stalker.classList.add('is-active', `_color-${color}`);
        });
        item.addEventListener('mouseleave', () => {
            const { color } = item.dataset;
            stalker.classList.remove('is-active', `_color-${color}`);
        });
    });

    return true;
}
