import $ from 'jquery';
import 'slick-carousel';
// import 'slick-carousel/slick/slick.scss';
// import 'slick-carousel/slick/slick-theme.scss';

function initSlider() {
    const stalker = $('#js-mouseStalker');
    if (window.ontouchstart !== undefined) return false;
    $(document).on('mouseenter', '.slick-slide', function(item) {
        const color = $(this).data('color');
        stalker.addClass(`is-active _color-${color}`);
    });
    $(document).on('mouseleave', '.slick-slide', function(item) {
        const color = $(this).data('color');
        stalker.removeClass(`is-active _color-${color}`);
    });
    return true;
}

function setTopSpotSlider() {
    const SLICK_ID = '#jsTopSpotSlider';
    if ($(SLICK_ID).length === 0) return false;

    const slide = $(`${SLICK_ID} .slick-slide`);
    const controller = $(`${SLICK_ID} .slick-controller`);
    const navCurrent = $(`${SLICK_ID} .slick-navigation__current`);
    const navTotal = $(`${SLICK_ID} .slick-navigation__total`);
    navTotal.text(slide.length);

    if (slide.length <= 1) {
        controller.hide();
    }

    const slickObject = $(`${SLICK_ID} .slick-wrapper`);
    slickObject.on('init', (slick) => {
        $(slick.target).find('div.slick-slide').each((index, item) => {
            if ($(item).hasClass('slick-active')) {
                $(item).attr({ 'aria-hidden': false });
                $(item).find('a.slick-slide').attr({ 'aria-hidden': false });
            }
        });
    });
    slickObject.on('afterChange', (slick, currentSlick) => {
        navCurrent.text(currentSlick.currentSlide + 1);
    });
    slickObject.slick({
        accessibility: true,
        infinite: true,
        // slidesToShow: 1,
        centerMode: true,
        variableWidth: true,
        draggable: false,
        arrows: true,
        appendArrows: $(`${SLICK_ID} .slick-controller`),
        prevArrow: '<button class="slick-pagination-prev" aria-label="prev"><span class="icon _caret-left"></span></button>',
        nextArrow: '<button class="slick-pagination-next" aria-label="next"><span class="icon _caret-right"></span></button>',
        // responsive: [
        //     {
        //         breakpoint: 798,
        //         settings: {
        //             slidesToShow: 1,
        //         },
        //     },
        // ],
    });
    return true;
}

function setTopEventSlider() {
    const SLICK_ID = '#jsTopEventSlider';
    if ($(SLICK_ID).length === 0) return false;

    const slide = $(`${SLICK_ID} .slick-slide`);
    const controller = $(`${SLICK_ID} .slick-controller`);
    const navCurrent = $(`${SLICK_ID} .slick-navigation__current`);
    const navTotal = $(`${SLICK_ID} .slick-navigation__total`);
    navTotal.text(slide.length);

    if (slide.length <= 1) {
        controller.hide();
    }

    const slickObject = $(`${SLICK_ID} .slick-wrapper`);
    slickObject.on('init', (slick) => {
        $(slick.target).find('div.slick-slide').each((index, item) => {
            if ($(item).hasClass('slick-active')) {
                $(item).attr({ 'aria-hidden': false });
                $(item).find('a.slick-slide').attr({ 'aria-hidden': false });
            }
        });
    });
    slickObject.on('afterChange', (slick, currentSlick) => {
        navCurrent.text(currentSlick.currentSlide + 1);
    });
    slickObject.slick({
        infinite: true,
        // slidesToShow: 1,
        centerMode: true,
        variableWidth: true,
        draggable: false,
        arrows: true,
        appendArrows: $(`${SLICK_ID} .slick-controller`),
        prevArrow: '<button class="slick-pagination-prev" aria-label="prev"><span class="icon _caret-left"></span></button>',
        nextArrow: '<button class="slick-pagination-next" aria-label="next"><span class="icon _caret-right"></span></button>',
    });
    return true;
}

export { initSlider, setTopSpotSlider, setTopEventSlider };
