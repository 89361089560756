export default function selectLang() {
    const selects = document.querySelectorAll('.js-selectLang');

    if (selects.length === 0) return false;

    selects.forEach((select) => {
        select.addEventListener('change', (event) => {
            window.location.href = event.target.value;
        });
    });
    return false;
}
