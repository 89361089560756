import Swiper from 'swiper';
import { Pagination, Navigation, Thumbs } from 'swiper/modules';
import 'swiper/scss';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

function setTopSpotSlider() {
    const swiperElement = document.querySelector('#jsTopSpotSlider');
    if (swiperElement === null) return false;

    const SLIDE_WIDTH = 352;
    const SLIDE_GAP = 32;
    const WINDOW_WIDTH = window.innerWidth;
    const SLIDER_LENGTH = swiperElement.querySelectorAll('.swiper-slide').length;

    // console.log(WINDOW_WIDTH / (SLIDE_WIDTH + SLIDE_GAP));

    const swiper = new Swiper(swiperElement, {
        modules: [Pagination, Navigation],
        slidesPerView: 1,
        centeredSlides: true,
        spaceBetween: SLIDE_GAP,
        // centerInsufficientSlides: true,
        loop: true,
        // loopAdditionalSlides: 1,
        initialSlide: 0,
        navigation: {
            nextEl: '.js-top-spot-slider-pagination-next',
            prevEl: '.js-top-spot-slider-pagination-prev',
        },
        pagination: {
            el: '.js-top-spot-slider-navigation',
            type: 'fraction',
        },
        breakpoints: {
            450: {
                slidesPerView: 'auto',
            },
        },
        on: {
            afterInit: (sw) => {
                const slideLength = sw.slides.length;
                const activeSlide = sw.slides.filter((slide) => slide.classList.contains('swiper-slide-active'));
                const activeIndex = activeSlide[0].dataset.index;
                if (activeIndex === String(slideLength)) {
                    sw.slideNext();
                }
            },
        },
    });
    return swiper;
}

function setTopEventSlider() {
    const swiperElement = document.querySelector('#jsTopEventSlider');
    if (swiperElement === null) return false;

    const swiper = new Swiper(swiperElement, {
        modules: [Pagination, Navigation],
        slidesPerView: 1,
        spaceBetween: 32,
        // centerInsufficientSlides: true,
        loop: true,
        // loopAdditionalSlides: 1,
        centeredSlides: true,
        initialSlide: 0,
        pagination: {
            el: '.js-top-event-slider-navigation',
            type: 'fraction',
        },
        navigation: {
            nextEl: '.js-top-event-slider-pagination-next',
            prevEl: '.js-top-event-slider-pagination-prev',
        },
        breakpoints: {
            768: {
                slidesPerView: 'auto',
            },
        },
        on: {
            afterInit: (sw) => {
                const slideLength = sw.slides.length;
                const activeSlide = sw.slides.filter((slide) => slide.classList.contains('swiper-slide-active'));
                const activeIndex = activeSlide[0].dataset.index;
                if (activeIndex === String(slideLength)) {
                    sw.slideNext();
                }
            },
        },
    });
    return swiper;
}

function setContentsSlider() {
    const sliderMain = document.querySelector('#js-sliderContentsMain');
    const sliderThumb = document.querySelector('#js-sliderContentsThumb');

    if (sliderMain === null || sliderThumb === null) return;

    const swiperThumb = new Swiper(sliderThumb, {
        modules: [Navigation],
        loop: false,
        spaceBetween: 20,
        slidesPerView: 4,
        freeMode: true,
        watchSlidesProgress: true,
        navigation: {
            disabledClass: 'is-disabled',
            hiddenClass: 'is-hidden',
        },
    });

    const swiperMain = new Swiper(sliderMain, {
        modules: [Navigation, Thumbs],
        loop: false,
        spaceBetween: 0,
        navigation: {
            nextEl: '#js-sliderContentsMainNext',
            prevEl: '#js-sliderContentsMainPrev',
        },
        thumbs: {
            swiper: swiperThumb,
        },
    });
}

export { setTopSpotSlider, setTopEventSlider, setContentsSlider };
