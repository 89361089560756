import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

function closeSpMenu() {
    const menu = document.querySelector('#js-menuSp');
    const menuModal = document.querySelector('#js-menuSpModal');

    if (menu === null || menuModal === null) return false;
    menu.classList.remove('is-active');
    menuModal.classList.remove('is-active');

    return true;
}

function scrollToSection(sectionId) {
    const section = document.querySelector(sectionId);
    if (section === null) return false;

    const headerHeight = document.querySelector('.site-header').offsetHeight;
    const sectionPosition = section.offsetTop;
    const y = window.scrollY;
    window.location = sectionId;
    gsap.set(window, { scrollTo: y });
    gsap.to(window, {
        duration: 0.5,
        scrollTo: sectionPosition - headerHeight + 180,
    });

    closeSpMenu();

    return true;
}

function hashChange() {
    const { hash } = window.location;
    if (hash !== '') {
        scrollToSection(hash);
    }
}

function setMenuMain() {
    window.addEventListener('load', () => {
        hashChange();
    });

    if (!document.querySelector('body').classList.contains('page-top')) return;
    document.querySelectorAll('.js-link-top-scroll').forEach((link) => {
        link.addEventListener('click', (event) => {
            event.preventDefault();
            const hash = link.getAttribute('href').match(/#.+$/);
            if (hash === null) return false;
            scrollToSection(hash);
            return hash;
        });
    });
}

export default setMenuMain;
