import { gsap, ScrollToPlugin, ScrollTrigger } from 'gsap/all';

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

export default function toTop() {
    const trigger = document.querySelector('#js-toTop');
    const footer = document.querySelector('#js-siteFooter');
    const siteMain = document.querySelector('#js-siteMain');

    if (trigger === null) return false;

    trigger.addEventListener('click', (event) => {
        event.preventDefault();
        gsap.to(window, { duration: 0.5, scrollTo: { y: 0 } });
    });

    ScrollTrigger.create({
        trigger: footer,
        start: '0% 100%',
        end: '0% 100%',
        markers: false,
        onEnter: () => {
            trigger.classList.add('is-absolute');
        },
        onEnterBack: () => {
            trigger.classList.remove('is-absolute');
        },
    });

    ScrollTrigger.create({
        trigger: siteMain,
        start: '1000px 50%',
        end: '1000px 50%',
        markers: false,
        onEnter: () => {
            trigger.classList.add('is-active');
        },
        onEnterBack: () => {
            trigger.classList.remove('is-active');
        },
    });

    return true;
}
