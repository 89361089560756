export default function pageCourse() {
    const siteHeader = document.querySelector('#js-siteHeader');
    const map = document.querySelector('#jsCourseMap');

    if (siteHeader === null || map === null) return false;

    const h = siteHeader.clientHeight;
    const margin = (window.matchMedia('(max-width: 759px)').matches) ? 0 : 43;
    map.style.top = `${h + margin}px`;

    return true;
}
