import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/src/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const LAT_LNG_DEFAULT = { lat: 36.06210948882524, lng: 136.2231516838074 };
const BREAK_POINT = '(max-width: 640px)';
const ICON_SIZE_PC = [106, 123];
const ICON_SIZE_SP = [52, 60];
const LANG = {
    ja: {
        east: '東口',
        west: '西口',
    },
    en: {
        east: 'East Exit',
        west: 'West Exit',
    },
    tc: {
        east: '東口',
        west: '西口',
    },
    sc: {
        east: '东口',
        west: '西口',
    },
};

function createTitleLayer() {
    const tileLayer = L.tileLayer(
        'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        {
            attribution: '© <a href="http://osm.org/copyright">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
        },
    );
    return tileLayer;
}

function setTextLabel(MAP, lang) {
    const TEXTS = L.featureGroup();

    const textEast = LANG[lang].east !== undefined ? LANG[lang].east : LANG.ja.east;
    const textWest = LANG[lang].west !== undefined ? LANG[lang].west : LANG.ja.west;

    const textData = [
        { title: textEast, lat: 36.06161946452079, lng: 136.2246805429459 },
        { title: textWest, lat: 36.06191001027316, lng: 136.22236847877505 },
    ];
    const mediaQuery = window.matchMedia(BREAK_POINT);
    const iconSize = mediaQuery.matches ? [44, 12] : [80, 32];
    textData.forEach((data) => {
        const text = L.marker([data.lat, data.lng], {
            icon: L.divIcon({
                html: `<div class="title">${data.title}</div>`,
                className: 'map-label',
                iconSize: iconSize,
            }),
        });
        TEXTS.addLayer(text);
    });
    TEXTS.addTo(MAP);
}

function setTopMap() {
    const topDinoMap = document.querySelector('#topDinoMap');

    if (topDinoMap === null) return false;

    const MAP = L.map('topDinoMap', {
        center: [LAT_LNG_DEFAULT.lat, LAT_LNG_DEFAULT.lng],
        zoom: 16,
        maxZoom: 18,
        minZoom: 10,
        scrollWheelZoom: false,
    });

    const TILE_LAYER = createTitleLayer();
    TILE_LAYER.addTo(MAP);

    const topMapLang = topDinoMap.dataset.lang;
    setTextLabel(MAP, topMapLang);

    const MARKERS = L.featureGroup();
    const markersData = JSON.parse(topDinoMap.dataset.markers);

    const mediaQuery = window.matchMedia(BREAK_POINT);
    const iconSize = mediaQuery.matches ? ICON_SIZE_SP : ICON_SIZE_PC;

    if (markersData.length > 0) {
        markersData.forEach((data) => {
            const MARKER = L.marker([data.lat, data.lng], {
                icon: L.divIcon({
                    html: `<div class="marker">
                            <div class="marker-img">
                                <img src="${data.img}" alt="">
                            </div>
                        </div>
                        <div class="title">${data.title}</div>`,
                    className: 'map-div-icon js-map-div-icon',
                    iconSize: iconSize,
                }),
            });
            MARKER.addEventListener('click', () => {
                window.location = data.href;
            });
            MARKER.addEventListener('mouseover', () => {
                MARKER.setZIndexOffset(1000);
            });
            MARKER.addEventListener('mouseout', () => {
                MARKER.setZIndexOffset(0);
            });
            MARKERS.addLayer(MARKER);
        });
        MARKERS.addTo(MAP);

        if (mediaQuery.matches) {
            MAP.fitBounds(MARKERS.getBounds().pad(0.1));
        } else {
            MAP.fitBounds(MARKERS.getBounds());
        }
    }

    return MAP;
}

function setCourseMap() {
    const mapStage = document.querySelector('#jsCourseMap');
    const lists = document.querySelectorAll('.js-course-list');

    if (mapStage === null || lists.length === 0) return false;

    const MAP = L.map('jsCourseMap', {
        zoom: 15,
        maxZoom: 18,
        minZoom: 10,
        scrollWheelZoom: false,
    });

    const TILE_LAYER = createTitleLayer();
    TILE_LAYER.addTo(MAP);

    const courseMapLang = mapStage.dataset.lang;
    setTextLabel(MAP, courseMapLang);

    const MARKERS_DATA = [];
    const MARKERS = [];
    const markerGroup = L.featureGroup();

    lists.forEach((list) => {
        const { pos } = list.dataset;
        MARKERS_DATA.push(JSON.parse(pos));
    });

    const mediaQuery = window.matchMedia(BREAK_POINT);
    const iconSize = mediaQuery.matches ? ICON_SIZE_SP : ICON_SIZE_PC;

    MARKERS_DATA.forEach((marker) => {
        const M = L.marker([marker.lat, marker.lng], {
            icon: L.divIcon({
                html: `<div class="marker"><div class="marker-img"><img src="${marker.img}" alt=""></div></div><div class="title">${marker.title}</div>`,
                className: 'map-div-icon js-map-div-icon',
                iconSize: iconSize,
            }),
        });
        markerGroup.addLayer(M);
        M.addEventListener('mouseover', () => {
            M.setZIndexOffset(1000);
        });
        M.addEventListener('mouseout', () => {
            M.setZIndexOffset(0);
        });
        M.addTo(MAP);
        MARKERS.push({ id: marker.id, marker: M });
    });

    if (mediaQuery.matches) {
        MAP.fitBounds(markerGroup.getBounds().pad(0.1));
    } else {
        MAP.fitBounds(markerGroup.getBounds());
    }

    function checkMarker(id) {
        MARKERS.forEach((marker) => {
            if (marker.id === id) {
                marker.marker._icon.classList.add('is-active');
                marker.marker.setZIndexOffset(1000);
            } else {
                marker.marker._icon.classList.remove('is-active');
                marker.marker.setZIndexOffset(0);
            }
        });
    }

    document.querySelectorAll('.js-course-list').forEach((listItem) => {
        ScrollTrigger.create({
            trigger: listItem,
            start: '0% 40%',
            end: '100% 80%',
            markers: false,
            onEnter: () => {
                const pos = JSON.parse(listItem.dataset.pos);
                checkMarker(pos.id);
                // MAP.panTo([pos.lat, pos.lng], { animate: true, duration: 0.5, noMoveStart: true });
                // MARKER.setLatLng([pos.lat, pos.lng]);
            },
            onEnterBack: () => {
                const pos = JSON.parse(listItem.dataset.pos);
                checkMarker(pos.id);
                // MAP.panTo([pos.lat, pos.lng], { animate: true, duration: 0.5, noMoveStart: true });
                // MARKER.setLatLng([pos.lat, pos.lng]);
            },
            onLeave: () => {
                // console.log('leave');
            },
        });
    });

    return true;
}

function setCourseFooterMap() {
    const MAP_AREA = document.querySelector('#js-courseFooterMap');

    if (MAP_AREA === null) return false;

    const data = JSON.parse(MAP_AREA.dataset.map);
    const MAP = L.map('js-courseFooterMap', {
        center: [data.lat, data.lng],
        zoom: 16,
        maxZoom: 18,
        minZoom: 10,
        scrollWheelZoom: false,
    });

    const TILE_LAYER = createTitleLayer();
    TILE_LAYER.addTo(MAP);

    const courseFooterMapLang = MAP_AREA.dataset.lang;
    setTextLabel(MAP, courseFooterMapLang);

    const mediaQuery = window.matchMedia(BREAK_POINT);
    const iconSize = mediaQuery.matches ? ICON_SIZE_SP : ICON_SIZE_PC;

    const marker = L.marker([data.lat, data.lng], {
        icon: L.divIcon({
            html: `<div class="marker"><div class="marker-img"><img src="${data.img}" alt=""></div></div>`,
            className: 'map-div-icon js-map-div-icon',
            iconSize: iconSize,
        }),
    });
    marker.addTo(MAP);

    return MAP;
}

function setDinoMap() {
    const mapArea = document.querySelector('#js-dinoMap');

    if (mapArea === null) return false;

    const data = JSON.parse(mapArea.dataset.map);
    const MAP = L.map('js-dinoMap', {
        center: [data.lat, data.lng],
        zoom: 16,
        maxZoom: 18,
        minZoom: 10,
        scrollWheelZoom: false,
    });
    const TILE_LAYER = createTitleLayer();
    TILE_LAYER.addTo(MAP);

    const DinoMapLang = mapArea.dataset.lang;
    setTextLabel(MAP, DinoMapLang);

    const mediaQuery = window.matchMedia(BREAK_POINT);
    const iconSize = mediaQuery.matches ? ICON_SIZE_SP : ICON_SIZE_PC;

    const marker = L.marker([data.lat, data.lng], {
        icon: L.divIcon({
            html: `<div class="marker"><div class="marker-img"><img src="${data.img}" alt=""></div></div>`,
            className: 'map-div-icon js-map-div-icon',
            iconSize: iconSize,
        }),
    });
    marker.addTo(MAP);

    return true;
}

export {
    setTopMap,
    setCourseFooterMap,
    setCourseMap,
    setDinoMap,
};
