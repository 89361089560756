export default function spMenu() {
    const trigger = document.querySelector('#js-menuSp');
    const modal = document.querySelector('#js-menuSpModal');

    if (trigger === null || modal === null) return false;

    trigger.addEventListener('click', (event) => {
        if (trigger.classList.contains('is-active')) {
            trigger.classList.remove('is-active');
            modal.classList.remove('is-active');
        } else {
            trigger.classList.add('is-active');
            modal.classList.add('is-active');
        }
    });
    return true;
}
